import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { createEditor, type Node } from 'slate'
import {
  Editable,
  Slate,
  withReact,
  type RenderElementProps,
  type RenderLeafProps,
} from 'slate-react'

import Toolbar from './Toolbar'
import Element from './Element'
import Leaf from './Leaf'
import { htmlToSlate, slateToHtml } from './converter'

type WysiwygProps = {
  name: string
  onUpload?: () => void
  imagesOnly?: boolean
  autoFocus?: boolean
}

const Wysiwyg: React.FC<WysiwygProps> = ({
  name,
  onUpload,
  imagesOnly = false,
  autoFocus = false,
}) => {
  const editorRef = useRef(null)
  const { defaultValues } = useFormState()
  const defaultValue = defaultValues?.[name]
  const initialValue = useMemo(() => htmlToSlate(defaultValue), [defaultValue])
  const editor = useRef(withReact(createEditor()))
  const { setValue } = useFormContext()
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])

  useEffect(() => {
    if (!autoFocus) {
      return
    }
    const timeout = setTimeout(() => {
      if (editorRef.current) {
        (editorRef.current as any).focus()
      }
    }, 100)
    return () => { clearTimeout(timeout) }
  }, [autoFocus])

  const handleChange = (value: Node[]) => {
    setValue(name, slateToHtml(value))
  }

  return (
    <Slate
      editor={editor.current}
      initialValue={initialValue}
      onChange={handleChange}
    >
      <Toolbar
        onUpload={onUpload}
        imagesOnly={imagesOnly}
      />
      <Editable
        ref={editorRef}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        className="mt-2 max-h-[600px] min-h-[150px] cursor-text overflow-y-auto rounded border border-neutral-200 p-4 outline-none ring-black hover:ring-1 focus-visible:ring-2"
      />
    </Slate>
  )
}

export default Wysiwyg
