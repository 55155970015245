import CalendarIcon from '@mui/icons-material/CalendarMonth'

import {
  dateInTimezone,
  useDateFormatter,
} from '../../../../utils/date'
import {
  useSelectedQuote,
  type Move,
} from '../../../../modules/moves'
import TimeControl from '../TimeControl'

type DateAndTimeSectionProps = {
  move: Move
}

const DateAndTimeSection: React.FC<DateAndTimeSectionProps> = ({
  move,
}) => {
  const formatDate = useDateFormatter()
  const selectedQuote = useSelectedQuote()

  if (!selectedQuote) {
    return null
  }

  return (
    <div className="flex flex-col justify-between gap-y-2 sm:flex-row">
      <div className="flex items-center gap-3 font-sans text-lg">
        <CalendarIcon className="!text-[18px] text-neutral-500" />
        <span>
          { formatDate(dateInTimezone(selectedQuote.date, move.originTimezone)) }
        </span>
      </div>

      <div className="flex items-center gap-3 font-sans text-xl">
        <TimeControl name="movingTime" />
      </div>
    </div>
  )
}

export default DateAndTimeSection
