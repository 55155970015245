import { useTranslation } from 'react-i18next'

import { useThemeLogo } from '../../../modules/theme'
import { ReactComponent as MovingWaldoLogo } from '../../../assets/logo.svg'
import { ReactComponent as LogoKangalou } from '../../../assets/logo-partners/logoKangalou.svg'

const logos = {
  kangalou: LogoKangalou,
  pillartopost: (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => <img src="/images/partners/logo-pillartopost.png" alt="PillarToPost" {...props} />,
  maxwellrealty: (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => <img src="/images/partners/logo-maxwellrealty.png" alt="MaxWell Realty®" {...props} />,
}

const ThemeLogo: React.FC = () => {
  const { t } = useTranslation()
  const themeLogo = useThemeLogo()
  if (!themeLogo) {
    return null
  }

  const LogoComponent = logos[themeLogo as keyof typeof logos]
  if (!LogoComponent) {
    return null
  }

  return (
    <div className="flex h-full items-end">
      <LogoComponent className="h-full" />
      <span className="ml-5 mr-3 block text-xs text-neutral-400">
        { t('header.poweredBy') }
      </span>
      <MovingWaldoLogo className="h-[25px]" />
    </div>
  )
}

export default ThemeLogo
