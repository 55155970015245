import { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import { useSelectedQuote, type Move } from '../../../../modules/moves'
import Link, { type LinkProps, LinkVariant } from '../../../Link'
import { SingleCheckbox } from '../../../Form'
import BookingTermsModal from '../BookingTermsModal'
import { useConstraintsWarnings } from '../useConstraintsWarnings'

const TermsLink: React.FC<Partial<LinkProps>> = props => {
  const { t } = useTranslation()

  return (
    <Link
      variant={LinkVariant.Primary}
      {...props}
      to="#"
    >
      { t('forms.checkout.terms') }
    </Link>
  )
}

type ConditionsSectionProps = {
  move: Move
}

const ConditionsSection: React.FC<ConditionsSectionProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const [tacOpen, setTacOpen] = useState(false)
  const form = useFormContext()
  const selectedQuote = useSelectedQuote()
  const constraintsWarnings = useConstraintsWarnings()

  const hasConstraintsWarnings = Object.keys(constraintsWarnings).length > 0
  const hasCustomQuote = !!selectedQuote?.customized

  const handleAcceptTac = () => {
    form.setValue('termsAccepted', true, { shouldValidate: true })
    setTacOpen(false)
  }

  return (
    <>
      <BookingTermsModal
        open={tacOpen}
        onClose={() => { setTacOpen(false) }}
        onAccept={handleAcceptTac}
      />

      { hasConstraintsWarnings && (
        <div className="my-2 rounded-md border border-orange-200 bg-orange-50 px-4 py-2">
          <SingleCheckbox
            name="acceptConstraints"
            label={(
              <div className="prose max-w-none pl-1 font-sans text-sm leading-5 text-neutral-700">
                <span className="text-neutral-400">
                  { t('forms.checkout.IAcknowledgeTheFollowing') }:
                </span>
                <ul>
                  { Object.entries(constraintsWarnings).map(([constraint, warning]) => (
                    <li key={constraint} className="child:m-0">
                      { warning }
                    </li>
                  )) }
                </ul>
              </div>
            )}
          />
        </div>
      ) }

      <div className="my-2 rounded-md border bg-neutral-100 px-4 py-2">
        <SingleCheckbox
          name="estimateAcknowledged"
          label={(
            <div className="py-2 pl-1 font-sans text-sm leading-5 text-neutral-800">
              { hasCustomQuote
                ? t('forms.checkout.acknowledgeEstimateCustomQuote')
                : t('forms.checkout.acknowledgeEstimate') }
            </div>
          )}
        />
      </div>

      <div className="my-2 rounded-md border bg-neutral-100 px-4 py-2">
        <SingleCheckbox
          name="termsAccepted"
          label={(
            <div className="py-2 pl-1 font-sans text-sm leading-5 text-neutral-800">
              <Trans i18nKey="forms.checkout.acceptTerms">
                <TermsLink onClick={() => { setTacOpen(true) }} />
              </Trans>
            </div>
          )}
        />
      </div>
    </>
  )
}

export default ConditionsSection
