import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'

import { useLanguage, useChangeLanguageAction } from '../../../modules/i18n'
import Menu, { MenuItem } from '../../Menu'

const LanguageDropdown: React.FC = () => {
  const currentLanguage = useLanguage()
  const changeLanguage = useChangeLanguageAction()

  return (
    <Menu
      buttonContent={(
        <div className="text-md flex gap-2 text-gray-800">
          { currentLanguage?.toLocaleUpperCase() } <ChevronDownIcon className="w-[14px]" />
        </div>
      )}
    >
      <div>
        <MenuItem onClick={() => { changeLanguage('fr') }}>
          Français
        </MenuItem>
      </div>

      <div>
        <MenuItem onClick={() => { changeLanguage('en') }}>
          English
        </MenuItem>
      </div>
    </Menu>

  )
}

export default LanguageDropdown
