import { useEffect, useRef } from 'react'

const ONE_SEC = 1000

type QueueTransitionOptions = {
  startingGroup: unknown[]
  endingGroup: unknown[]
  transitionTimeoutMs?: number
}

/**
 * This ensure a smooth visual transition when moving items between 2 groups
 * This hook avoid visual bouncing on screen, caused by asyncronous operations (ex: GraphQL)
 */
const useQueueTransition = ({
  startingGroup,
  endingGroup,
  transitionTimeoutMs = ONE_SEC,
}: QueueTransitionOptions) => {
  const transitioning = useRef(false)
  const startCount = useRef(startingGroup.length)
  const endCount = useRef(endingGroup.length)

  const itemStartedMoving = startingGroup.length < startCount.current
  const itemArrived = endingGroup.length > endCount.current

  startCount.current = startingGroup.length
  endCount.current = endingGroup.length

  if (itemStartedMoving) {
    transitioning.current = true
  }

  if (itemArrived) {
    transitioning.current = false
  }

  /**
   * if an item is moving for too long, reset transitionning flag
   * that could mean the operation failed
   */
  useEffect(() => {
    if (!transitioning.current) {
      return
    }
    const timeout = setTimeout(() => {
      transitioning.current = false
    }, transitionTimeoutMs)
    return () => { clearTimeout(timeout) }
  }, [transitionTimeoutMs])

  return { transitioning: transitioning.current }
}

export default useQueueTransition
