import Link, { LinkVariant } from '../../components/Link'

const FrenchContent: React.FC = () => (
  <>
    <h2>Veuillez tenir compte de ce qui suit</h2>

    <ul>
      <li>Informez-nous et/ou votre déménageur de tout changement dans votre plan de déménagement.</li>
      <li>Soyez prêt et emballé autant que possible pour assurer un déménagement sans encombre. <Link to="https://www.movingwaldo.com/fr/astuces-demenagement/aide-memoires-checklist-demenagement/" target="_blank" variant={LinkVariant.Primary}>Voici un aide-mémoire pour mieux vous aider.</Link></li>
      <li>Réservez une place de stationnement pour les déménageurs près de l'entrée.</li>
      <li>Réitérez les instructions de stationnement ou d'ascenseur à votre déménageur.</li>
      <li>Déplacez vous-même les bijoux et les petits objets de valeur pour limiter la responsabilité.</li>
      <li>Les déménageurs peuvent refuser de déplacer certains objets s'ils les jugent dangereux, ou peuvent exiger une décharge de responsabilité pour les objets qui ne peuvent pas être correctement protégés.</li>
    </ul>

    <h2>Le paiement</h2>

    <ul>
      <li>Le paiement sera débité de votre carte de crédit par MovingWaldo une fois le déménagement terminé (ou le jour ouvrable suivant). Vous recevrez une facture finale de notre part.</li>

      <li>Ceci nous permet de sécuriser la transaction. Si vous avez des doutes sur votre facture, contactez-nous et nous réviserons le tout.</li>

      <li>Les modifications apportées à votre inventaire pourraient impacter le coût final. Pour planifier en conséquence, veuillez informer votre agent de réservation de tout changement.</li>
    </ul>

    <h2>Assurance et responsabilité</h2>

    <ul>
      <li>Le déménageur qui effectuera le déménagement sera assuré auprès d'une compagnie d'assurance dûment enregistrée pour la responsabilité civile et les dommages matériels.

        <ul>
          <li>
            À titre informatif, <strong>la protection de base dans l'industrie du déménagement est de 60 cents par livre par article</strong>. La plupart de nos partenaires offrent une couverture supplémentaire. Des frais y sont associés, et cette protection doit être achetée avant le déménagement. Pour plus de détails, veuillez consulter votre agent de réservation.
          </li>
        </ul>
      </li>

      <li>
        En cas de dommages pendant le déménagement, le déménageur sera tenu responsable.
        <ul>
          <li>
            Cela ne couvre pas les dommages survenus avant le déménagement ou les dommages résultant de conditions non divulguées existant avant le début du déménagement.
          </li>
        </ul>
      </li>
    </ul>

    <strong>Assistance dans le processus de réclamation:</strong> Bien que le processus de réclamation doit être mené directement avec le déménageur, MovingWaldo est dévouée à vous soutenir à chaque étape du processus. Pour ce faire, nous avons une page dédiée au <Link to="https://www.movingwaldo.com/fr/processus-de-reclamation/" target="_blank" variant={LinkVariant.Primary}>processus de réclamation</Link>. Nous vous encourageons vivement à consulter cette page <strong>avant</strong> le déménagement. Veuillez noter les points suivants :

    <ul>
      <li>Le processus de réclamation doit être débuté le plus tôt possible. Merci de remplir <Link to="https://www.movingwaldo.com/fr/processus-de-reclamation/" target="_blank" variant={LinkVariant.Primary}>ce formulaire</Link> ou de nous contacter dans les <strong>24 heures suivant le déménagement</strong>.</li>
      <li><strong>Votre déménagement doit être payé en totalité afin de traiter une réclamation.</strong></li>
    </ul>

    <h2>Politique d'annulation et de modification</h2>
    <h3>Annulation</h3>

    <ul>
      <li>Vous pouvez annuler votre déménagement dans les 10 jours suivant l'acceptation de l'estimé, si c'est fait au moins 72 heures avant la date prévue, en écrivant à <Link to="mailto:bookings@movingwaldo.com" variant={LinkVariant.Primary}>bookings@movingwaldo.com</Link>.</li>
      <li>Passé ce délai, mais au moins 72 heures avant le déménagement, des frais d'annulation de 100 $ s'appliqueront.</li>
      <li>Toute annulation à moins de 72 heures du déménagement entraînera des frais équivalents à 3 heures au tarif horaire indiqué dans l'estimé.</li>
    </ul>

    <h3>Modification</h3>
    <ul>
      <li>Les demandes de changement de date doivent être faites au moins 72 heures avant la date de déménagement prévue.</li>
      <li>Si faites dans les 72 heures, des frais équivalents à 3 heures au tarif horaire seront facturés.</li>
      <li>La disponibilité de l'entreprise initiale n'est pas garantie. En cas d'indisponibilité, MovingWaldo vous aidera à trouver un autre prestataire, et l'estimation sera ajustée à la tarification du nouveau partenaire.</li>
    </ul>

    Pour toute question, communiquez avec nous à <Link to="mailto:bookings@movingwaldo.com" variant={LinkVariant.Primary}>bookings@movingwaldo.com</Link>.

    <br />
    Bon déménagement!

  </>
)

export default FrenchContent
