import { type Node } from 'slate'
import * as htmlSerializer from '@slate-serializers/html'

/**
 * convert HTML into slate nodes
 * returns empty text node by default if no nodes are generated
 */
export const htmlToSlate = (html?: string): any => {
  const nodes = htmlSerializer.htmlToSlate(html ?? '', {
    ...htmlSerializer.htmlToSlateConfig,
    elementTags: {
      p: () => ({ type: 'paragraph' }),
      ul: () => ({ type: 'bulleted-list' }),
      ol: () => ({ type: 'numbered-list' }),
      li: () => ({ type: 'list-item' }),
      blockquote: () => ({ type: 'block-quote' }),
    },
    textTags: {
      strong: () => ({ bold: true }),
      em: () => ({ italic: true }),
      u: () => ({ underline: true }),
    },
  })

  if (nodes.length === 0) {
    return [
      {
        type: 'paragraph',
        children: [
          { text: '' },
        ],
      },
    ]
  }

  return nodes
}

/**
 * export HTML from Slate nodes
 */
export const slateToHtml = (nodes: Node[]) => {
  const html = htmlSerializer.slateToHtml(nodes, {
    alwaysEncodeBreakingEntities: true,
    alwaysEncodeCodeEntities: false,
    convertLineBreakToBr: true,
    elementAttributeTransform: () => ({}),
    elementTransforms: {},
    encodeEntities: true,
    markMap: {
      bold: ['strong'],
      italic: ['em'],
      underline: ['u'],
    },
    elementMap: {
      paragraph: 'p',
      'bulleted-list': 'ul',
      'numbered-list': 'ol',
      'list-item': 'li',
      'block-quote': 'blockquote',
    },
  })

  const content = new DOMParser().parseFromString(html, 'text/html')
  const textContent = content.body.textContent?.replaceAll(/\s/g, '')

  if (!textContent) {
    return null
  }

  return html
}
