import movingwaldo from './movingwaldo.theme'
import kangalou from './kangalou.theme'
import pillartopost from './pillartopost.theme'
import maxwellrealty from './maxwellrealty.theme'

const themes = {
  movingwaldo,
  kangalou,
  pillartopost,
  maxwellrealty,
}

export default themes
