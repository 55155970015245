import {
  Editor,
  Element as SlateElement,
  Transforms,
} from 'slate'
import { useSlate } from 'slate-react'
import ToggleButton from '@mui/material/ToggleButton'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

type BlockButtonProps = {
  format: string
  icon?: React.ReactNode
}

const BlockButton: React.FC<BlockButtonProps> = ({ format, icon }) => {
  const editor = useSlate()

  const isBlockActive = () => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (node) =>
          !Editor.isEditor(node) &&
          SlateElement.isElement(node) &&
          (node as any).type === format,
      }),
    )

    return !!match
  }

  const toggleBlock = (event: React.MouseEvent) => {
    event.preventDefault()

    const isActive = isBlockActive()
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
      match: (node) =>
        !Editor.isEditor(node) &&
        SlateElement.isElement(node) &&
        LIST_TYPES.includes((node as any).type),
      split: true,
    })

    const newProperties: any = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }

  return (
    <ToggleButton
      value={format}
      selected={isBlockActive()}
      onMouseDown={toggleBlock}
      size="small"
    >
      { icon }
    </ToggleButton>
  )
}

export default BlockButton
