const Element: React.FC<any> = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote
          {...attributes}
          className="ml-3 border-l-4 border-neutral-300 px-3 py-1 text-neutral-400"
        >
          { children }
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul
          {...attributes}
          className="ml-6 list-disc"
        >
          { children }
        </ul>
      )
    case 'list-item':
      return (
        <li
          {...attributes}
        >
          { children }
        </li>
      )
    case 'numbered-list':
      return (
        <ol
          {...attributes}
          className="ml-6 list-decimal"
        >
          { children }
        </ol>
      )
    default:
      return (
        <p {...attributes}>
          { children }
        </p>
      )
  }
}

export default Element
