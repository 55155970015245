import { useTranslation } from 'react-i18next'

import {
  useSelectedQuote,
  type Move,
} from '../../../../modules/moves'
import {
  usePromoCodeLabel,
  usePromoCodeRebate,
  useQuoteSubtotalWithPromoCode,
} from '../../../../modules/promoCodes'
import Price from '../../../Price'

type PromoCodeSectionProps = {
  move: Move
}

const PromoCodeSection: React.FC<PromoCodeSectionProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const selectedQuote = useSelectedQuote()
  const promoCodeRebate = usePromoCodeRebate(selectedQuote, move.promoCode)
  const quoteSubtotalWithPromoCode = useQuoteSubtotalWithPromoCode(selectedQuote, move.promoCode)
  const currency = selectedQuote?.subtotal?.currency
  const promoCodeLabel = usePromoCodeLabel(move.promoCode, currency)

  if (!selectedQuote) {
    return null
  }

  return (
    <div className="mt-8 flex flex-col items-end">
      { promoCodeRebate && quoteSubtotalWithPromoCode
        ? (
          <>
            <div className="flex w-full items-center justify-end font-sans text-base uppercase text-neutral-400">
              <div className="line-through child:line-through">
                <Price amount={selectedQuote.subtotal} />
              </div>
            </div>
            <div className="mb-8 flex w-full max-w-[400px] items-center justify-between gap-8 font-sans text-base uppercase text-green-800">
              { promoCodeLabel }
              <div className="text-xl font-bold">
                <Price amount={promoCodeRebate} />
              </div>
            </div>

            <div className="flex w-full max-w-[400px] items-center justify-between gap-8 font-sans text-base uppercase text-neutral-600">
              { t('pages.checkout.estimate') }:
              <div className="text-xl font-bold">
                <Price amount={quoteSubtotalWithPromoCode} showCurrency />
                <div className="text-right text-sm font-normal">
                  ({ t('quotes.quote.plusTaxes') })
                </div>
              </div>
            </div>
          </>
          )
        : (
          <div className="flex w-full max-w-[400px] items-center justify-between gap-8 font-sans text-base uppercase text-neutral-600">
            { t('pages.checkout.estimate') }:
            <div className="text-xl font-bold">
              <Price amount={selectedQuote.subtotal} showCurrency />
              <div className="text-right text-sm font-normal">
                ({ t('quotes.quote.plusTaxes') })
              </div>
            </div>
          </div>
          ) }
    </div>
  )
}

export default PromoCodeSection
