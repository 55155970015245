import { Editor } from 'slate'
import { useSlate } from 'slate-react'
import ToggleButton from '@mui/material/ToggleButton'

type MarkButtonProps = {
  format: string
  icon?: React.ReactNode
}

const MarkButton: React.FC<MarkButtonProps> = ({ format, icon }) => {
  const editor = useSlate()

  const isMarkActive = () => {
    const marks = Editor.marks(editor) as any
    return !!marks?.[format]
  }

  const toggleMark = (event: React.MouseEvent) => {
    event.preventDefault()
    const isActive = isMarkActive()
    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }

  return (
    <ToggleButton
      value={format}
      selected={isMarkActive()}
      onMouseDown={toggleMark}
      size="small"
    >
      { icon }
    </ToggleButton>
  )
}

export default MarkButton
