import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import reduce from 'image-blob-reduce'
import Pica from 'pica'

import { isImage } from '../../../../utils/file'
import {
  type FileWithId,
  type UploadHandler,
} from './NoteField.types'

type AttachmentsOptions = {
  imageMaxSize?: number
}

/**
 * handle a list of attachments
 * call the upload handler for each file one at a time and remove them from the queue once uploaded
 * it's the responsability of the handler to handle errors
 */
const useAttachments = (
  handleUpload?: UploadHandler,
  { imageMaxSize }: AttachmentsOptions = {},
) => {
  const [lastBatchLength, setLastBatchLength] = useState<number>()
  const [queue, setQueue] = useState<FileWithId[]>([])
  const [uploading, setUploading] = useState(false)
  const getOptimalFileSize = useGetOptimalFileSize(imageMaxSize)

  const progress = (queue.length && lastBatchLength && lastBatchLength > 1)
    ? Math.min(((lastBatchLength - queue.length) / lastBatchLength) * 100, 100)
    : null

  /**
   * upload next file in queue
   */
  useEffect(() => {
    const nextInQueue = queue[0]
    if (uploading || !handleUpload || !nextInQueue) {
      return
    }

    setUploading(true)
    const upload = async () => {
      const optimizedFile = await getOptimalFileSize(nextInQueue)
      await handleUpload(optimizedFile)
    }

    upload().finally(() => {
      setUploading(false)
      removeAttachment(nextInQueue.id)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, uploading])

  /**
   * push new attachments to the queue to be uploaded
   * a unique id is generated for each file
   */
  const addAttachments = (files: File[]) => {
    const newFiles = [...files] as FileWithId[]
    newFiles.forEach(file => {
      if (!file.id) {
        file.id = uuid()
      }
    })
    setLastBatchLength(files.length)
    setQueue(existingFiles => [...existingFiles, ...newFiles])
  }

  /**
   * remove a pending file from the queue
   */
  const removeAttachment = (id: string) => {
    if (uploading && queue[0]?.id === id) {
      return
    }
    setQueue(existingFiles => existingFiles.filter(attachment => attachment.id !== id))
  }

  return {
    pendingAttachments: queue,
    addAttachments,
    removeAttachment,
    uploading: queue.length > 0,
    progress,
  }
}

export default useAttachments

/**
 * if the file is an image, this resize image to optimize the upload size
 */
const useGetOptimalFileSize = (imageMaxSize?: number) => {
  return async (file: File): Promise<File> => {
    if (!imageMaxSize || !isImage(file.name, { onlyBinary: true })) {
      return file
    }
    try {
      const pica = Pica({ features: ['js', 'wasm', 'cib'] })
      const blob = await reduce({ pica }).toBlob(file, { max: imageMaxSize })
      return new File([blob], file.name, file)
    } catch (error) {
      console.error(error)
      return file
    }
  }
}
