export enum FileType {
  BMP = 'image/bmp',
  GIF = 'image/gif',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  WEBP = 'image/webp',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const binaryImageFileTypes = [
  FileType.BMP,
  FileType.GIF,
  FileType.JPG,
  FileType.PNG,
  FileType.WEBP,
]

export const imageFileTypes = [
  ...binaryImageFileTypes,
  FileType.SVG,
]

export const applicationFileTypes = [
  FileType.PDF,
  FileType.DOC,
  FileType.DOCX,
  FileType.XLS,
  FileType.XLSX,
]

export const allFileTypes = [
  ...imageFileTypes,
  ...applicationFileTypes,
]
