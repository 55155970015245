import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import WaitIcon from '@mui/icons-material/HourglassBottomOutlined'
import DeleteIcon from '@mui/icons-material/HighlightOffOutlined'

import { mergeClassName } from '../../utils/mergeClassName'
import Spinner from '../Spinner'
import ConfirmationModal from '../ConfirmationModal'
import FileIcon from './FileIcon'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type FileProps = {
  filename: string
  id?: string
  preview?: string
  waiting?: boolean
  loading?: boolean
  onDelete?: (id: string) => Promise<void> | void
} & ReactDiv

const File: React.FC<FileProps> = ({
  filename,
  id,
  preview,
  waiting = false,
  loading = false,
  onDelete,
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const [previewError, setPreviewError] = useState(false)
  const [deleting, setDeleting] = useState(false)

  if (previewError) {
    preview = undefined
  }

  const showFilename = !preview
  const showDelete = !!id && !!onDelete && !loading && !deleting

  if (deleting) {
    loading = true
  }

  /**
   * handle file delete
   */
  const handleDelete = async () => {
    if (loading || !id || !onDelete) {
      return
    }
    try {
      setDeleting(true)
      await onDelete(id)
    } catch (error) {
      setDeleting(false)
    }
  }

  return (
    <div
      className={mergeClassName(
        'relative size-[100px] overflow-hidden rounded-xl border border-neutral-300 shadow-sm',
        className,
      )}
      {...props}
    >
      { preview
        ? (
          <img
            src={preview}
            alt=""
            className="size-[100px] object-cover"
            onError={() => { setPreviewError(true) }}
          />
          )
        : (
          <FileIcon
            filename={filename}
            className="flex h-[70px] pt-1"
          />
          ) }

      { loading && (
        <div className="left:0 absolute top-0 flex size-full items-center justify-center bg-white/40">
          <div className="flex size-[40px] items-center justify-center rounded-xl bg-white/85 p-1 shadow">
            <div className="flex size-full scale-125 items-center justify-center">
              <Spinner small className="" />
            </div>
          </div>
        </div>
      ) }

      { waiting && !loading && (
        <div className="left:0 absolute top-0 flex size-full items-center justify-center bg-white/70">
          <div className="flex size-[40px] items-center justify-center rounded-xl bg-white/85 p-1 shadow">
            <WaitIcon className="!text-[22px] text-neutral-400" />
          </div>
        </div>
      ) }

      { showDelete && (
        <ConfirmationModal
          question={t('components.file.deleteConfirmation')}
          danger
          onConfirm={handleDelete}
        >
          <div
            className="absolute right-0 top-0 z-[3] m-1 flex cursor-pointer items-center justify-center transition-all hover:opacity-100 xl:left-0 xl:m-0 xl:size-full xl:bg-red-200/50 xl:opacity-0"
          >
            <div className="flex size-[40px] items-center justify-center rounded-xl bg-white p-1 shadow xl:bg-red-600/85">
              <DeleteIcon className="!text-[28px] text-red-600 xl:!text-[22px] xl:text-white" />
            </div>
          </div>
        </ConfirmationModal>
      ) }

      { showFilename && (
        <div className="absolute bottom-0 right-0 z-[2] w-full cursor-default p-1 text-[0.6rem] text-neutral-400">
          <div className="truncate text-center" title={filename}>
            { filename }
          </div>
        </div>
      ) }

    </div>
  )
}

export default File
