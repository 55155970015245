import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { type FileRejection, useDropzone } from 'react-dropzone'
import LinearProgress from '@mui/material/LinearProgress'
import AddIcon from '@mui/icons-material/AddCircleOutline'

import { type ExternalFile } from '../../../../common/file/file.type'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { useConfig } from '../../../../modules/config'
import { allFileTypes, binaryImageFileTypes } from '../../../Files'
import { errorNotification, warningNotification } from '../../../ToastNotifications'
import { Wysiwyg } from '../../controls'
import Files from './Files'
import { type DeleteFileHandler, type UploadHandler } from './NoteField.types'
import useAttachments from './useAttachments'

type NoteFieldProps = {
  name: string
  files?: ExternalFile[]
  onUpload?: UploadHandler
  onDeleteFile?: DeleteFileHandler
  onUploading?: (isUploading: boolean) => void
  imagesOnly?: boolean
  maxFiles?: number
  smoothTransition?: boolean
  autoFocus?: boolean
  className?: string
}

const NoteField: React.FC<NoteFieldProps> = ({
  name,
  files,
  onUpload,
  onDeleteFile,
  onUploading,
  imagesOnly = false,
  maxFiles = Infinity,
  smoothTransition,
  autoFocus,
  className,
}) => {
  const { t } = useTranslation()
  const imageMaxSize = useConfig<number>('instantBooking.notes.imageMaxSize')
  const fileTypes = imagesOnly ? binaryImageFileTypes : allFileTypes

  const {
    pendingAttachments,
    addAttachments,
    uploading,
    progress,
  } = useAttachments(onUpload, { imageMaxSize })

  /**
   * notify parent when we're uploading
   */
  useEffect(() => {
    onUploading?.(uploading)
  }, [onUploading, uploading])

  /**
   * new files uploaded (dropped)
   */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (uploading || acceptedFiles.length === 0) {
      return
    }

    const totalFiles = (files?.length ?? 0) + acceptedFiles.length

    if (totalFiles > maxFiles) {
      acceptedFiles = acceptedFiles.slice(0, maxFiles - totalFiles)

      warningNotification(t(
        'components.formFields.NoteField.errors.tooManyFiles',
        { count: maxFiles },
      ))

      if (acceptedFiles.length === 0) {
        return
      }
    }

    addAttachments(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading, maxFiles, files])

  /**
   * dropped files rejected
   */
  const onDropRejected = (rejections: FileRejection[]) => {
    const extensions = rejections.map(({ file }) => file.name.split('.').reverse()[0])

    errorNotification(t(
      'components.formFields.NoteField.errors.invalidFileExtensions',
      { extensions: extensions.join(', ') },
    ))
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    disabled: !onUpload,
    noClick: true,
    onDrop,
    onDropRejected,
    accept: Object.fromEntries(fileTypes.map(type => ([type, []]))),
  })

  const dragAndDropProps = !onUpload ? undefined : getRootProps()

  return (
    <div
      {...dragAndDropProps}
      onFocus={undefined}
      className={mergeClassName(
        'relative rounded border border-neutral-400 overflow-hidden',
        isDragActive && 'border-black',
        className,
      )}
    >
      { onUpload && <input {...getInputProps()} /> }

      { isDragActive && (
        <div className="absolute inset-px z-50 flex items-center justify-center rounded bg-white/5 backdrop-blur-sm">
          <div className="flex items-center gap-x-4 rounded-xl border bg-white px-6 py-4 font-sans text-lg shadow">
            <AddIcon />
            { t('components.formFields.NoteField.dropFiles') }
          </div>
        </div>
      ) }

      <div className="field-wrapper flex flex-col gap-y-4 p-4">
        <div>
          <Wysiwyg
            name={name}
            onUpload={onUpload ? openFileDialog : undefined}
            autoFocus={autoFocus}
            imagesOnly={imagesOnly}
          />
        </div>
        <Files
          files={files ?? []}
          pendingFiles={pendingAttachments}
          onDeleteFile={onDeleteFile}
          smoothTransition={smoothTransition}
        />
      </div>

      { uploading && (
        <LinearProgress
          value={progress ?? undefined}
          variant={progress !== null ? 'determinate' : 'indeterminate'}
        />
      ) }
    </div>
  )
}

export default NoteField
