import { type ExternalFile as ExternalFileType } from '../../../../common/file/file.type'
import { isImage } from '../../../../utils/file'
import { File } from '../../../Files'
import {
  type FileWithId,
  type DeleteFileHandler,
} from './NoteField.types'
import useQueueTransition from './useQueueTransition'

type FilesProps = {
  files?: ExternalFileType[]
  pendingFiles?: FileWithId[]
  onDeleteFile?: DeleteFileHandler
  smoothTransition?: boolean
}

const Files: React.FC<FilesProps> = ({
  files = [],
  pendingFiles = [],
  onDeleteFile,
  smoothTransition = false,
}) => {
  const { transitioning } = useQueueTransition({
    startingGroup: pendingFiles,
    endingGroup: files,
  })

  if (!files.length && !pendingFiles.length) {
    return null
  }

  return (
    <div className="flex flex-wrap justify-center gap-2 xl:justify-start">
      { files.map((file) => {
        const isImageFile = isImage(file.filename, { onlyBinary: false })
        return (
          <File
            key={file.id}
            id={file.id}
            filename={file.filename}
            preview={isImageFile ? file.url : undefined}
            onDelete={onDeleteFile}
          />
        )
      }) }

      { smoothTransition && transitioning && (
        <File
          filename=""
          loading
        />
      ) }

      { pendingFiles.map((file, index) => {
        const isImageFile = isImage(file.name, { onlyBinary: false })
        return (
          <File
            key={file.id}
            id={file.id}
            filename={file.name}
            preview={isImageFile ? URL.createObjectURL(file) : undefined}
            waiting
            loading={index === 0}
          />
        )
      }) }
    </div>
  )
}

export default Files
