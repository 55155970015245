import { useTranslation } from 'react-i18next'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import BoldIcon from '@mui/icons-material/FormatBold'
import ItalicIcon from '@mui/icons-material/FormatItalic'
import UnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import NumberedListIcon from '@mui/icons-material/FormatListNumbered'
import BulletedListIcon from '@mui/icons-material/FormatListBulleted'
import QuoteIcon from '@mui/icons-material/FormatQuote'
import AddIcon from '@mui/icons-material/AddCircleOutline'

import MarkButton from './MarkButton'
import BlockButton from './BlockButton'

type ToolbarProps = {
  onUpload?: () => void
  imagesOnly?: boolean
}

const Toolbar: React.FC<ToolbarProps> = ({
  onUpload,
  imagesOnly = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-between gap-y-2 md:flex-row">
      <div className="flex gap-x-2 md:gap-x-4">
        <ToggleButtonGroup>
          <MarkButton format="bold" icon={<BoldIcon />} />
          <MarkButton format="italic" icon={<ItalicIcon />} />
          <MarkButton format="underline" icon={<UnderlinedIcon />} />
        </ToggleButtonGroup>

        <ToggleButtonGroup>
          <BlockButton format="numbered-list" icon={<NumberedListIcon />} />
          <BlockButton format="bulleted-list" icon={<BulletedListIcon />} />
        </ToggleButtonGroup>

        <ToggleButtonGroup>
          <BlockButton format="block-quote" icon={<QuoteIcon />} />
        </ToggleButtonGroup>
      </div>

      { onUpload && (
        <ToggleButtonGroup>
          <ToggleButton
            value="upload"
            onClick={onUpload}
            size="small"
          >
            <div className="flex items-center gap-x-2 text-base normal-case">
              <AddIcon />
              { imagesOnly
                ? t('components.formControls.Wysiwyg.addPhotos')
                : t('components.formControls.Wysiwyg.addFiles') }
            </div>
          </ToggleButton>
        </ToggleButtonGroup>
      ) }
    </div>
  )
}

export default Toolbar
