import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  type AcceptQuotePayload,
  type PaymentRequest,
  useMove,
  useSelectedQuote,
} from '../../../modules/moves'
import Form, {
  SubmitButton,
} from '../../Form'
import { QuoteDetails } from '../../quotes/Quotes/Quote'
import SelectedQuote from '../../../pages/move/CheckoutPage/SelectedQuote'
import PromoCodeForm from '../PromoCodeForm'
import MoveDepositHeader from '../PaymentForm/MoveDepositHeader'
import { useConstraintsWarnings } from './useConstraintsWarnings'
import { useDefaultTime } from './useDefaultTime'
import { formatFormOutput } from './formDataBuilder'
import { type CheckoutFormValues } from './CheckoutForm.type'
import useValidationSchema from './validationSchema'
import NotesSection from './sections/NotesSection'
import ConditionsSection from './sections/ConditionsSection'
import PromoCodeSection from './sections/PromoCodeSection'
import DateAndTimeSection from './sections/DateAndTimeSection'

type CheckoutFormProps = {
  onSubmit: (values: AcceptQuotePayload) => Promise<void> | void
  onPromoCodeSubmit?: (promoCode: string | null) => Promise<void> | void
  paymentField?: React.ReactNode
  paymentRequest?: PaymentRequest
  hasValidPaymentField?: boolean
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  onSubmit,
  onPromoCodeSubmit,
  paymentField,
  paymentRequest,
  hasValidPaymentField = true,
}) => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const selectedQuote = useSelectedQuote()
  const constraintsWarnings = useConstraintsWarnings()
  const defaultTime = useDefaultTime(
    selectedQuote?.companyBranch.services.arrivalWindows ?? [],
    move.preferredTime ?? [],
  )

  const hasConstraintsWarnings = Object.keys(constraintsWarnings).length > 0

  const [uploading, setUploading] = useState(false)

  const schema = useValidationSchema({ hasConstraintsWarnings })
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      movingTime: defaultTime,
      origin: move.origin,
      destination: move.destination,
      note: move.notes?.fromCustomer?.note,
    },
  })

  const paymentFieldName = '_payment'
  const { submitCount } = useFormState({ control: form.control })

  const handleSubmit = async (data: CheckoutFormValues) => {
    await onSubmit(formatFormOutput(data))
  }

  /**
   * revalidate the form when constraints are updated
   */
  useEffect(() => {
    if (submitCount > 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      form.trigger()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasConstraintsWarnings])

  /**
   * set form error when there's a payment field is invalid
   */
  useEffect(() => {
    if (submitCount === 0) {
      return
    }
    if (hasValidPaymentField) {
      form.clearErrors(paymentFieldName)
    } else {
      form.setError(paymentFieldName, {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidPaymentField, submitCount])

  if (!selectedQuote) {
    return null
  }

  return (
    <>
      <div className="hidden xl:flex">
        <SelectedQuote />
      </div>

      <div className="p-4 pb-8 xl:py-8">
        <QuoteDetails
          quote={selectedQuote}
          open
          hideSubtotal
        />

        <PromoCodeSection
          move={move}
        />
      </div>

      { onPromoCodeSubmit && (
        <PromoCodeForm onSubmit={onPromoCodeSubmit} />
      ) }

      <Form form={form} onSubmit={handleSubmit}>

        { paymentField && paymentRequest && (
          <div className="my-4 flex flex-col justify-between gap-2 rounded-md border bg-white p-4 text-neutral-500 lg:p-8">
            <MoveDepositHeader paymentRequest={paymentRequest} />
            { paymentField }
          </div>
        ) }

        <div className="my-4 rounded-md border bg-white p-4 lg:p-8">

          <DateAndTimeSection
            move={move}
          />

          <NotesSection
            move={move}
            onUploading={setUploading}
          />
        </div>

        <ConditionsSection
          move={move}
        />

        <div className="mb-6 mt-8 flex w-full justify-end sm:w-auto xl:mb-0 xl:mt-6">
          <SubmitButton
            className="w-full sm:w-auto"
            disabled={uploading}
          >
            { t('actions.bookMyMove') }
          </SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default CheckoutForm
