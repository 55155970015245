import { useTranslation } from 'react-i18next'
import MessageIcon from '@mui/icons-material/ChatOutlined'

import { useConfig } from '../../../../modules/config'
import {
  useDeleteNoteAttachmentAction,
  useUploadNoteAttachmentAction,
  type Move,
} from '../../../../modules/moves'
import { NoteField } from '../../../Form'
import { useErrorFormatter } from '../../../errors/useErrorFormatter'
import { errorNotification } from '../../../ToastNotifications'

type NotesSectionProps = {
  move: Move
  onUploading: (isUploading: boolean) => void
}

const NotesSection: React.FC<NotesSectionProps> = ({
  move,
  onUploading,
}) => {
  const { t } = useTranslation()
  const maxAttachments = useConfig<number>('instantBooking.notes.maxAttachments')

  const { uploadNoteAttachment } = useUploadNoteAttachmentAction()
  const { deleteNoteAttachment } = useDeleteNoteAttachmentAction()
  const formatError = useErrorFormatter()

  const onUpload = async (file: File) => {
    try {
      await uploadNoteAttachment(file)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const onDeleteFile = async (fileId: string) => {
    try {
      await deleteNoteAttachment(fileId)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <div className="mt-4 flex flex-col justify-between gap-2 text-neutral-500">
      <div className="flex items-center gap-3 font-sans">
        <MessageIcon className="!text-[18px]" />
        { t('forms.fields.moversMessage.label') }
      </div>

      <NoteField
        name="note"
        imagesOnly
        files={move.notes?.fromCustomer?.attachments}
        onUpload={onUpload}
        onDeleteFile={onDeleteFile}
        onUploading={onUploading}
        maxFiles={maxAttachments}
        smoothTransition
      />
    </div>
  )
}

export default NotesSection
