import { useEffect } from 'react'

import { mergeClassName } from '../../../utils/mergeClassName'
import { scrollUp } from '../../../utils/scroll'
import Header from '../Header'
import Footer from '../Footer'

export type FullWidthPageProps = {
  children?: React.ReactNode
  className?: string
}

const FullWidthPage: React.FC<FullWidthPageProps> = ({
  children,
  className,
}) => {
  /**
   * scroll to the top whenever we render a new page
   * in our flow, we sometimes render a new page without updating the route
   */
  useEffect(() => {
    scrollUp()
  }, [])

  return (
    <div className={mergeClassName(
      'flex min-h-screen w-full flex-col items-center bg-neutral-50',
      className,
    )}
    >
      <Header />
      <div className="w-full grow">
        { children }
      </div>
      <Footer />
    </div>
  )
}

export default FullWidthPage
