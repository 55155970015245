type IsImageOptions = {
  onlyBinary?: boolean
}

/**
 * return true if the given filename has an image extension
 *  - "onlyBinary" is used to select only binary images (ex: jpg)
 *    as opposed to other images like svg
 */
export const isImage = (
  filename: string,
  { onlyBinary = true }: IsImageOptions = {},
) => {
  const extension = filename.split('.').reverse()[0].toLowerCase()

  const imageExtensions = [
    'bmp',
    'jpg',
    'jpeg',
    'gif',
    'png',
    'webp',
  ]
  if (!onlyBinary) {
    imageExtensions.push('svg')
  }
  return imageExtensions.includes(extension)
}
